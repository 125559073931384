import { useState } from "react";
import { WallpaperApiClient } from "../../api/clients/wallpapers-api-client";
import { PremiumToggleEntityProps } from "./types";

const useWallpaperPremiumToggle = (props: PremiumToggleEntityProps) => {
  const [isPremium, setIsPremium] = useState(props.isPremium);

  const toggleIsPremium = () => {
    const newValue = !isPremium;
    setIsPremium(newValue);

    try {
      WallpaperApiClient.update(props.entityId, {
        is_signature: newValue,
      });
    } catch (error) {
      setIsPremium(props.isPremium);
      console.error("Error updating wallpaper:", error);
      alert("Error updating wallpaper. Please try again later.");
    }
  };

  return {
    isPremium,
    toggleIsPremium,
  };
};

export default useWallpaperPremiumToggle;
