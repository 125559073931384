import { Collection } from "../../../api/models/collection";
import { CollectionListOptions } from "../components/CollectionListOptionsMenu";

export const applyOptions = (
  collections: Collection.Model[],
  options: CollectionListOptions
) => {
  let filteredCollections = [...collections];

  if (!options.showHiddenCollections) {
    filteredCollections = filteredCollections.filter(
      (collection) => collection.is_explorable
    );
  }

  switch (options.sortBy) {
    case "recency":
      filteredCollections.sort((a, b) => {
        if (a.kind === "collection" && b.kind === "category") {
          return -1;
        } else if (a.kind === "category" && b.kind === "collection") {
          return 1;
        } else {
          return b.created_at - a.created_at;
        }
      });
      break;
    case "views":
      filteredCollections.sort((a, b) => b.analytics.views - a.analytics.views);
      break;
    case "downloads":
      filteredCollections.sort(
        (a, b) => b.analytics.downloads - a.analytics.downloads
      );
      break;
    case "conversion":
      filteredCollections.sort(
        (a, b) => b.analytics.conversion - a.analytics.conversion
      );
      break;
  }

  return filteredCollections;
};
