import { PopupMenuButton } from "../../../../../components/menu-popover/PopupMenuButton";
import { useWallpaperCopySubmenu } from "./use-wallpaper-copy-submenu";

interface Props {
  wallpaperId: string;
  closeMenu: () => void;
}

export const WallpaperCopySubmenu: React.FC<Props> = (props) => {
  const { collections, isLoading, handleCollectionSelected } =
    useWallpaperCopySubmenu(props);

  return (
    <>
      {isLoading && <PopupMenuButton>Loading...</PopupMenuButton>}
      {!isLoading &&
        collections.map((collection) => (
          <PopupMenuButton
            key={collection.id}
            onClick={() => handleCollectionSelected(collection.id)}
          >
            {collection.metadata.title.en}
          </PopupMenuButton>
        ))}
    </>
  );
};
