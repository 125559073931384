import React from "react";
import styled from "styled-components";
import CardContainer from "../../../components/cards/CardContainer";
import { TagHighlightStyle, Tag } from "../../../components/tag/Tag";
import {
  CloudArrowDownFill,
  EyeFill,
  Fire,
  StarFill,
} from "react-bootstrap-icons";
import { CollectionCardActions } from "./actions/CollectionCardActions";
import { Link } from "react-router-dom";

interface Props {
  model: CollectionCardModel;
  onCollectionUpdated: () => void;
}

export interface CollectionCardModel {
  id: string;
  title: string;
  linkTo: string;
  isHidden: boolean;
  isPremium: boolean;

  views: string;
  viewsHighlightStyle?: TagHighlightStyle;

  downloads: string;
  downloadsHighlightStyle?: TagHighlightStyle;

  conversion: string;
  conversionHighlightStyle?: TagHighlightStyle;
}

const StyledCard = styled(CardContainer)`
  padding: 16px 24px 24px 24px;
`;

const Header = styled.div<{ $isMuted?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
  gap: 6px;

  h3 {
    flex-grow: 1;
    color: ${(props) =>
      props.$isMuted
        ? props.theme.colors.secondary
        : props.theme.colors.primary};
    font-size: 16px;
    font-weight: bold;
  }
`;

const TagsList = styled.ul<{ $isMuted?: boolean }>`
  display: flex;
  gap: 8px;
  align-items: center;
  opacity: ${(props) => (props.$isMuted ? 0.5 : 1)};

  li {
    list-style: none;
  }
`;

export const CollectionCard: React.FC<Props> = (props) => {
  return (
    <StyledCard as={Link} to={props.model.linkTo}>
      <Header $isMuted={props.model.isHidden}>
        {props.model.isPremium && <StarFill size={12} color="#f1c40f" />}
        <h3>{props.model.title}</h3>
        <CollectionCardActions
          collectionId={props.model.id}
          isHidden={props.model.isHidden}
          isPremium={props.model.isPremium}
          onCollectionUpdated={props.onCollectionUpdated}
        />
      </Header>

      <TagsList $isMuted={props.model.isHidden}>
        <li>
          <Tag
            highlightStyle={props.model.viewsHighlightStyle}
            tooltipText="Views"
          >
            <EyeFill size={14} />
            <span>{props.model.views}</span>
          </Tag>
        </li>

        <li>
          <Tag
            highlightStyle={props.model.downloadsHighlightStyle}
            tooltipText="Downloads"
          >
            <CloudArrowDownFill size={14} />
            <span>{props.model.downloads}</span>
          </Tag>
        </li>

        <li>
          <Tag
            highlightStyle={props.model.conversionHighlightStyle}
            tooltipText="Conversion"
          >
            <Fire size={14} />
            <span>{props.model.conversion}</span>
          </Tag>
        </li>
      </TagsList>
    </StyledCard>
  );
};
