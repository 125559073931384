import {
  BookmarkStarFill,
  BookmarkXFill,
  EyeFill,
  EyeSlashFill,
  RocketTakeoffFill,
  Trash2Fill,
} from "react-bootstrap-icons";
import { PopupMenu } from "../../../../components/menu-popover/PopupMenu";
import { useCollectionActions } from "./use-collection-actions";
import { PopupMenuButton } from "../../../../components/menu-popover/PopupMenuButton";
import { ReactNode } from "react";

interface Props {
  collectionId: string;
  isHidden: boolean;
  isPremium: boolean;
  onCollectionUpdated: () => void;
}

type MenuContent = (closeMenu: () => void) => ReactNode;

export const CollectionCardActions: React.FC<Props> = (props) => {
  const {
    handleToggleHidden,
    handleTogglePremium,
    handlePromote,
    handleDelete,
  } = useCollectionActions(props);

  const content: MenuContent = (closeMenu) => {
    const onToggleHidden = () => {
      closeMenu();
      handleToggleHidden();
    };

    const onTogglePremium = () => {
      closeMenu();
      handleTogglePremium();
    };

    const onPromote = () => {
      closeMenu();
      handlePromote();
    };

    const onDelete = () => {
      closeMenu();
      handleDelete();
    };

    return (
      <>
        <PopupMenuButton onClick={onToggleHidden}>
          {props.isHidden ? <EyeFill size={12} /> : <EyeSlashFill size={12} />}
          {props.isHidden ? "Show" : "Hide"}
        </PopupMenuButton>

        <PopupMenuButton onClick={onTogglePremium}>
          {props.isPremium ? (
            <BookmarkXFill size={12} />
          ) : (
            <BookmarkStarFill size={12} />
          )}
          {props.isPremium ? "Make Free" : "Make Premium"}
        </PopupMenuButton>

        <PopupMenuButton onClick={onPromote}>
          <RocketTakeoffFill size={12} />
          Promote
        </PopupMenuButton>

        <PopupMenuButton isDestructive={true} onClick={onDelete}>
          <Trash2Fill size={12} />
          Delete
        </PopupMenuButton>
      </>
    );
  };

  return <PopupMenu>{content}</PopupMenu>;
};
