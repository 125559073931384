import { useCallback, useEffect, useState } from "react";
import { CollectionApiClient } from "../../../api/clients/collections-api-client";
import { Collection } from "../../../api/models/collection";
import { useNavigate } from "react-router-dom";

const useCollection = (collectionId?: string) => {
  const [collection, setCollection] = useState<Collection.Model | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const loadCollection = useCallback(async () => {
    if (!collectionId) return;
    setLoading(true);
    try {
      const [fetchedCollection] = await CollectionApiClient.fetchByIds([
        collectionId,
      ]);
      setCollection(fetchedCollection);
    } catch (error) {
      console.error("Error fetching collection:", error);
    } finally {
      setLoading(false);
    }
  }, [collectionId]);

  useEffect(() => {
    loadCollection();
  }, [loadCollection]);

  const updateCollectionProperty = async (
    property: keyof Collection.Model,
    value: boolean
  ) => {
    if (!collection) return;

    const updatedCollection = { ...collection, [property]: value };
    setCollection(updatedCollection);

    try {
      await CollectionApiClient.update(collection.id, { [property]: value });
    } catch (error) {
      setCollection(collection);
      alert("Error updating collection. Please try again later.");
      console.error(`Error updating collection ${property}:`, error);
    }
  };

  const toggleVisibility = () =>
    updateCollectionProperty("is_explorable", !collection?.is_explorable);
  const togglePremium = () =>
    updateCollectionProperty("is_premium", !collection?.is_premium);

  const deleteCollection = async () => {
    if (!collection) return;
    try {
      await CollectionApiClient.drop(collection.id);
      navigate("/");
    } catch (error) {
      console.error("Error deleting collection:", error);
    }
  };

  return {
    collection,
    loading,
    reloadCollection: loadCollection,
    toggleVisibility,
    togglePremium,
    deleteCollection,
  };
};

export default useCollection;
