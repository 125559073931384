import { styled } from "styled-components";
import { breakpoints } from "../../styles/theme";

export const OptionsMenu = styled.section`
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;

  @media (max-width: ${breakpoints.desktop}) {
    flex-direction: column;
    justify-content: center;
    gap: 16px;
  }
`;

interface OptionsButtonProps {
  $isSelected?: boolean;
  $isDestructive?: boolean;
}

const resolveButtonBackgroundColor = (props: OptionsButtonProps) => {
  if (props.$isDestructive) {
    return "#ede6e2";
  } else if (props.$isSelected) {
    return "#86959F";
  } else {
    return "#E5E9E8";
  }
};

const resolveButtonTextColor = (props: OptionsButtonProps) => {
  if (props.$isDestructive) {
    return "#F1554F";
  } else if (props.$isSelected) {
    return "#fff";
  } else {
    return "#767877";
  }
};

const resolveHoverBackgroundColor = (props: OptionsButtonProps) => {
  if (props.$isDestructive) {
    return "#e9d5c9";
  } else if (props.$isSelected) {
    return "#616f78";
  } else {
    return "#dae0df";
  }
};

export const OptionsMenuButton = styled.button<OptionsButtonProps>`
  background: ${(props) => resolveButtonBackgroundColor(props)};
  color: ${(props) => resolveButtonTextColor(props)};

  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;

  letter-spacing: 1px;
  border: none;
  border-radius: 3vmin;
  padding: 10px 14px;
  cursor: pointer;

  display: flex;
  gap: 8px;
  align-items: center;

  transition: background 0.2s ease, color 0.2s ease;

  &:hover {
    background: ${(props) => resolveHoverBackgroundColor(props)};
  }
`;
