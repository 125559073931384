import { CollectionApiClient } from "../../../../api/clients/collections-api-client";
import { useNavigate } from "react-router-dom";
import { useConfirmation } from "../../../../components/confirm-dialog/ConfirmationContext";

interface Props {
  collectionId: string;
  isHidden: boolean;
  isPremium: boolean;
  onCollectionUpdated: () => void;
}

export const useCollectionActions = (props: Props) => {
  const confirmation = useConfirmation();
  const navigate = useNavigate();

  const handleToggleHidden = async () => {
    try {
      await CollectionApiClient.update(props.collectionId, {
        is_explorable: props.isHidden,
      });
      props.onCollectionUpdated();
    } catch (error) {
      console.error("Error updating collection:", error);
    }
  };

  const handleTogglePremium = async () => {
    try {
      await CollectionApiClient.update(props.collectionId, {
        is_premium: !props.isPremium,
      });
      props.onCollectionUpdated();
    } catch (error) {
      console.error("Error updating collection:", error);
    }
  };

  const handlePromote = () => {
    navigate(`/collections/${props.collectionId}/promote`);
  };

  const handleDelete = async () => {
    const confirmDelete = await confirmation.confirm(
      "Are you sure?",
      "Delete collection"
    );

    if (!confirmDelete) {
      return;
    }

    try {
      await CollectionApiClient.drop(props.collectionId);
      props.onCollectionUpdated();
    } catch (error) {
      console.error("Error deleting collection:", error);
      alert("Failed to delete collection.");
    }
  };

  return {
    handleToggleHidden,
    handleTogglePremium,
    handlePromote,
    handleDelete,
  };
};
