import { styled } from "styled-components";

interface PopupMenuButtonProps {
  isDestructive?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  children?: React.ReactNode;
}

const StyledPopupMenuButton = styled.button<{ $isDestructive?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 16px;
  width: 100%;

  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  text-align: start;

  color: ${(props) => (props.$isDestructive ? "#E74C3C" : "#151e1d")};

  cursor: pointer;
  background: none;
  border: none;

  &:hover {
    background: #f1f5f4;
  }
`;

export const PopupMenuButton: React.FC<PopupMenuButtonProps> = (props) => {
  return (
    <StyledPopupMenuButton
      $isDestructive={props.isDestructive}
      onClick={(e) => {
        e.preventDefault();
        props.onClick && props.onClick(e);
      }}
    >
      {props.children}
    </StyledPopupMenuButton>
  );
};
