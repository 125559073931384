import React from "react";
import styled from "styled-components";
import CardContainer from "../../../components/cards/CardContainer";
import { Link } from "react-router-dom";
import { Tag, TagHighlightStyle } from "../../../components/tag/Tag";
import { SendFill, StopwatchFill } from "react-bootstrap-icons";
import { PromotionCardActions } from "./actions/PromotionCardActions";

interface Props {
  model: PromotionCardModel;
  onPromotionUpdated: () => void;
}

export interface PromotionCardModel {
  id: string;
  title: string;
  description: string;
  badge: string;
  linkTo: string;

  pushDateText: string;
  pushDateHighlightStyle?: TagHighlightStyle;
  endDateText: string;
  endDateHighlightStyle?: TagHighlightStyle;
}

const StyledCard = styled(CardContainer)`
  padding: 16px 24px 24px 24px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 8px;

  h3 {
    margin-top: 8px;
    color: ${(props) => props.theme.colors.secondary};
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.8px;
  }
`;

const Title = styled.p`
  color: ${(props) => props.theme.colors.primary};
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.2px;
  margin-bottom: 8px;
  max-width: 320px;
`;

const Description = styled.p`
  color: ${(props) => props.theme.colors.primary};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.1px;
  max-width: 320px;
  margin-bottom: 24px;
  height: 38px;
`;

const TagsList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;

  li {
    list-style: none;
  }
`;

export const PromotionCard: React.FC<Props> = (props) => {
  return (
    <StyledCard as={Link} to={props.model.linkTo}>
      <Header>
        <h3>{props.model.badge}</h3>
        <PromotionCardActions
          promotionId={props.model.id}
          onPromotionUpdated={props.onPromotionUpdated}
        />
      </Header>
      <Title>{props.model.title}</Title>
      <Description>{props.model.description}</Description>
      <TagsList>
        <li>
          <Tag highlightStyle={props.model.pushDateHighlightStyle}>
            <SendFill size={14} />
            <span>{props.model.pushDateText}</span>
          </Tag>
        </li>

        <li>
          <Tag highlightStyle={props.model.endDateHighlightStyle}>
            <StopwatchFill size={14} />
            <span>{props.model.endDateText}</span>
          </Tag>
        </li>
      </TagsList>
    </StyledCard>
  );
};
