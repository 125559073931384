import { StarFill } from "react-bootstrap-icons";
import { styled } from "styled-components";
import { PremiumToggleEntityProps, PremiumToggleHookResult } from "./types";

interface Props {
  entityProps: PremiumToggleEntityProps;
  hook: (props: PremiumToggleEntityProps) => PremiumToggleHookResult;
}

const StyledPremiumToggle = styled.button<{ $isActive?: boolean }>`
  color: ${(props) => (props.$isActive ? "#ffbe32" : "#bcc5c4")};
  border: none;
  background: none;
  cursor: pointer;
  transition: color 0.2s ease;

  &:hover {
    color: ${(props) => (props.$isActive ? "#ecac21" : "#8d9594")};
  }
`;

export const PremiumToggle: React.FC<Props> = (props) => {
  const { isPremium, toggleIsPremium } = props.hook(props.entityProps);

  const handleClick: React.MouseEventHandler = (e) => {
    e.preventDefault();
    toggleIsPremium();
  };

  return (
    <StyledPremiumToggle $isActive={isPremium} onClick={handleClick}>
      <StarFill size={18} />
    </StyledPremiumToggle>
  );
};
